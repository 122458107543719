import * as React from "react"

// CSS & SCSS
import "./assets/fonts/inter.css"
import "./assets/fonts/material.css"
import "./assets/fonts/icofont/icofont.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/sass/styles.scss"
import "react-toastify/dist/ReactToastify.css"

// JS & COMPONENTS
import "./i18n"
import { createRoot } from "react-dom/client"
import { ThemeProvider } from "./context/Themes"
import { SidebarProvider } from "./context/Sidebar"
import { LoaderProvider } from "./context/Preloader"
import { TranslatorProvider } from "./context/Translator"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { LoginPage } from "./pages/auth"

import { SettingsPage } from "./pages/main"
import MappingsPage from "./pages/screens/MappingsPage"
import DealsPage from "./pages/screens/DealsPage"
import RulesPage from "./pages/screens/OrdersPage"
import DashboardPage from "./pages/screens/DashboardPage"
import toast, { Toaster } from "react-hot-toast"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"
import RulesEditPage from "./pages/screens/RulesEditPage"
import { withAuth } from "./utils/withAuth"
import { ToastContainer } from "react-toastify"

const queryClient = new QueryClient()

const router = createBrowserRouter([
  // MAIN PAGES MappingsPage
  { path: "/dashboard", Component: withAuth(DashboardPage) },
  { path: "/mappings", Component: withAuth(MappingsPage) },
  { path: "/deals", Component: withAuth(DealsPage) },
  { path: "/rules", Component: withAuth(RulesPage) },
  { path: "/rules/edit/:id", Component: withAuth(RulesEditPage) },
  { path: "/settings", Component: withAuth(SettingsPage) },
  // OTHER PAGES
  { path: "/", Component: withAuth(DashboardPage) },
  // WITHOUT AUTH
  { path: "/login", Component: LoginPage },
  { path: "*", Component: withAuth(DashboardPage) },
])

createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <LoaderProvider>
        <TranslatorProvider>
          <SidebarProvider>
            <RouterProvider router={router} />
            <ToastContainer
              enableMultiContainer
              containerId="solid"
              position="bottom-left"
              theme="colored"
              closeButton={false}
              autoClose={1000}
              style={{ minWidth: 340, fontSize: 14 }}
            />
          </SidebarProvider>
        </TranslatorProvider>
      </LoaderProvider>
    </ThemeProvider>
    <Toaster position="top-right" />
  </QueryClientProvider>
)
